import { useNavigate } from "react-router-dom";

import {
  Button,
  Divider,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { DraftIcon } from "components/icons/DraftIcon";
import { EmailOpen } from "components/icons/EmailOpen";

import { getTrackedNotification } from "modules/dashboard/application/getTrackedNotification";
import { useNotificationProcessQuery } from "modules/notifications/infrastructure/useNotificationProcessQuery";

import { DashboardTile } from "../DashboardTile";
import { TrackedNotification } from "./TrackedNotification";

export const MenageNotificationTile = () => {
  const { data, isLoading } = useNotificationProcessQuery();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <>
        <GridItem colSpan={2}>
          <Skeleton height="100%" />
        </GridItem>
      </>
    );
  }

  const drafts = data?.notificationsProcesses.filter(
    ({ isLocked, isCompleted }) => !isLocked && !isCompleted
  );

  return (
    <DashboardTile icon={<EmailOpen />} title={t("Powiadomienia")}>
      <VStack align="stretch" justifyContent="space-between" h="100%">
        <VStack align="stretch">
          <HStack>
            <DraftIcon size="24px" color="purple.500" />
            <Text fontSize="lg" fontWeight="semibold">
              {t("Wersje robocze: {{drafts}}", {
                drafts: drafts.length,
              })}
            </Text>
          </HStack>
          <Divider m={0} />
          <TrackedNotification
            trackedNotification={getTrackedNotification(
              data?.notificationsProcesses
            )}
          />
        </VStack>
        <Button
          mt="auto"
          colorScheme="purple"
          onClick={() => navigate("/powiadomienia")}
          variant="outline"
        >
          {t("Przejdź do powiadomień")}
        </Button>
      </VStack>
    </DashboardTile>
  );
};
