import { t } from "i18next";

import { CheckboxGroup } from "components/Filters/CheckboxGroup";
import { FilterDrawer } from "components/Filters/FilterDrawer";

export const ManagersWithOrganizationsFiltersDrawer = () => {
  return (
    <FilterDrawer
      drawerTitle={t("Filtry listy administratorów")}
      trackedParams={["active"]}
    >
      <CheckboxGroup
        filterId="active"
        label={t("Status administratora")}
        options={[
          ["false", "Nieaktywny"],
          ["true", "Aktywny"],
        ]}
      />
    </FilterDrawer>
  );
};
