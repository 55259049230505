import {
  Button,
  ButtonProps,
  Center,
  Flex,
  HStack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormLabel,
  BoxProps,
  FormControl,
} from "@chakra-ui/react";
import { t } from "i18next";
import { range } from "lodash";

import { ChevronDoubleLeftIcon } from "components/icons/ChevronDoubleLeftIcon";
import { ChevronDoubleRightIcon } from "components/icons/ChevronDoubleRightIcon";
import { ChevronLeftIcon } from "components/icons/ChevronLeftIcon";
import { ChevronRightIcon } from "components/icons/ChevronRightIcon";

interface Props extends BoxProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage?: number;
  onPageChange: (page: number) => void;
  pageNeighboursLength?: number;
}

export const Pagination = ({
  currentPage = 1,
  totalItems = 0,
  itemsPerPage = 10,
  onPageChange,
  pageNeighboursLength = 2,
  ...props
}: Props) => {
  if (totalItems === 0) return null;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleChange = (page: number) => {
    onPageChange(page);
  };

  const middlePagesArray = range(
    currentPage - pageNeighboursLength,
    currentPage + pageNeighboursLength + 1
  ).filter((page) => page > 1 && page < totalPages);

  const showLeftTruncateDots = currentPage - pageNeighboursLength - 1 > 1;
  const showRightTruncateDots =
    currentPage + pageNeighboursLength + 1 < totalPages;

  const resultsRangeFrom = itemsPerPage * currentPage - (itemsPerPage - 1);
  const resultsRangeTo =
    currentPage === totalPages ? totalItems : itemsPerPage * currentPage;

  const ResultsText = (
    <Text
      fontSize={"sm"}
      color={"gray.700"}
      display={{ base: "none", md: "block" }}
    >
      {t("Wyniki {{resultsRangeFrom}}-{{resultsRangeTo}} z {{totalItems}}", {
        resultsRangeFrom,
        resultsRangeTo,
        totalItems,
      })}
    </Text>
  );

  return (
    <HStack justify={{ base: "center", md: "space-between" }} {...props}>
      {ResultsText}
      <Flex>
        <Button
          size="sm"
          onClick={() => handleChange(1)}
          isDisabled={currentPage === 1}
          variant={"ghost"}
          aria-label="Left double button"
          data-testid="left-double-button"
          display={{ base: "none", sm: "block" }}
        >
          <ChevronDoubleLeftIcon />
        </Button>
        <Button
          size="sm"
          onClick={() => handleChange(currentPage === 1 ? 1 : currentPage - 1)}
          isDisabled={currentPage === 1}
          variant={"ghost"}
          aria-label="Left button"
          data-testid="left-button"
          display={{ base: "none", sm: "block" }}
        >
          <ChevronLeftIcon />
        </Button>
        <PaginationButton
          key={1}
          isActive={currentPage === 1}
          onClick={() => handleChange(1)}
          data-testid="pagination-button-1"
        >
          {1}
        </PaginationButton>
        {showLeftTruncateDots && <Center mx={1}>...</Center>}
        {middlePagesArray.map((page) => (
          <PaginationButton
            key={page}
            isActive={currentPage === page}
            onClick={() => handleChange(page)}
            data-testid={`pagination-button-${page}`}
          >
            {page}
          </PaginationButton>
        ))}
        {showRightTruncateDots && <Center mx={1}>...</Center>}
        {totalPages > 1 && (
          <PaginationButton
            key={totalPages}
            isActive={currentPage === totalPages}
            onClick={() => handleChange(totalPages)}
            data-testid={`pagination-button-${totalPages}`}
          >
            {totalPages}
          </PaginationButton>
        )}
        <Button
          size="sm"
          variant={"ghost"}
          onClick={() =>
            handleChange(
              currentPage === totalPages ? totalPages : currentPage + 1
            )
          }
          isDisabled={currentPage === totalPages}
          aria-label="Right button"
          data-testid="right-button"
          display={{ base: "none", sm: "block" }}
        >
          <ChevronRightIcon />
        </Button>
        <Button
          size="sm"
          variant={"ghost"}
          onClick={() => handleChange(totalPages)}
          isDisabled={currentPage === totalPages}
          aria-label="Right double button"
          data-testid="right-double-button"
          display={{ base: "none", sm: "block" }}
        >
          <ChevronDoubleRightIcon />
        </Button>
      </Flex>

      <HStack spacing={6} display={{ base: "none", md: "flex" }}>
        <FormControl as={HStack}>
          <FormLabel m={0} fontSize="sm" color={"gray.700"}>
            {t("Strona")}
          </FormLabel>
          <NumberInput
            size="sm"
            step={1}
            min={1}
            max={totalPages}
            maxW={20}
            value={currentPage}
            onChange={(val) => handleChange(Number(val))}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </HStack>
    </HStack>
  );
};

interface PaginationButtonProps extends ButtonProps {
  isActive: boolean;
  children: React.ReactNode;
}

const PaginationButton = ({
  isActive,
  children,
  ...rest
}: PaginationButtonProps) => {
  return (
    <Button
      size="sm"
      variant={"outline"}
      fontWeight={500}
      color={isActive ? "purple.500" : "gray.600"}
      borderColor={isActive ? "purple.500" : "gray.600"}
      borderWidth={isActive ? "2px" : "1px"}
      mx={1}
      {...rest}
    >
      {children}
    </Button>
  );
};
