import { useEffect, useState } from "react";

import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ActiveBadge } from "components/ActiveBadge";
import { ClearIcon } from "components/icons/ClearIcon";

import { useContextsManagersQuery } from "modules/organization/infrastructure/useContextsManagersQuery";

interface IProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

export const ContextManagerSearch = ({ value, onChange }: IProps) => {
  const [search, setSearch] = useState<string>("");
  const [focused, setFocused] = useState(false);
  const { data } = useContextsManagersQuery();

  // posortowane od najnowszych
  const managers = reverse(
    sortBy(
      data?.contextsManagers.filter((manager) => {
        if (search === "") {
          return true;
        }

        return (
          manager.name.toLowerCase().includes(search.toLowerCase()) ||
          manager.email.toLowerCase().includes(search.toLowerCase())
        );
      }),
      function (data) {
        return new Date(data.createdAt);
      }
    )
  );

  const setManagerName = () => {
    setSearch(
      data?.contextsManagers.find((manager) => manager.id === value)?.name || ""
    );
  };

  useEffect(() => {
    if (value) {
      setManagerName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <InputGroup>
        <Input
          value={search}
          placeholder={t("Szukaj")}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() =>
            setFocused(() => {
              setManagerName();
              return false;
            })
          }
        />
        {value && (
          <InputRightElement width="4.5rem">
            <IconButton
              aria-label="clear"
              h="1.75rem"
              size="sm"
              variant="ghost"
              colorScheme="purple"
              icon={<ClearIcon />}
              onClick={() => {
                setSearch("");
                onChange(null);
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {focused && (
        <Box position="relative">
          <List
            position={"absolute"}
            zIndex={1}
            mt={2}
            maxH="300px"
            left={0}
            right={2}
            overflowY="auto"
            bgColor="white"
            boxShadow="md"
          >
            {managers.map((manager) => {
              return (
                <ListItem
                  key={manager.id}
                  _hover={{ bgColor: "gray.100" }}
                  p={2}
                  cursor="pointer"
                  onMouseDown={() => {
                    onChange(manager.id);
                  }}
                >
                  <HStack>
                    <Text fontWeight="500">{manager.name}</Text>
                    <ActiveBadge isActive={manager.active} />
                  </HStack>
                  <Text fontSize="sm" color="gray.600">
                    {manager.email}
                  </Text>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </>
  );
};
