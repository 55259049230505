import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  contextId: string;
}

export const useUnassignContextManager = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["unassign-context-manager"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(
        `contextsManagers/contexts/${variables.contextId}/unassignOrganization`,
        {
          body: {
            organizationId,
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
      queryClient.invalidateQueries({
        queryKey: ["contextsManagers"],
      });
    },
  });
};
