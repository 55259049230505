import { createStandaloneToast } from "@chakra-ui/react";
import { assertEvent, assign, fromPromise, setup } from "xstate";

import { getToken } from "utils/getToken";

import { changeOrganization } from "../infrastructure/changeOrganization";
import { getUserInfoQuery } from "../infrastructure/getUserInfoQuery";
import { impersonate } from "../infrastructure/impersonate";
import { login } from "../infrastructure/login";
import { relogin } from "../infrastructure/relogin";
import { IUserInfo } from "./types/IUserInfo";

export const contextMachine = setup({
  types: {
    context: {} as {
      token: string | null;
      userInfo: IUserInfo | null;
    },
    events: {} as
      | { type: "logout"; onLogout?: () => void }
      | { type: "sync" }
      | { type: "login"; username: string; password: string }
      | { type: "relog"; contextId: string }
      | { type: "swapOrganization"; organizationId: string }
      | { type: "impersonate"; login: string },
  },
  actors: {
    login: fromPromise(
      async ({ input }: { input: { username: string; password: string } }) => {
        try {
          const { userInfo } = await login(input);

          localStorage.setItem("token", userInfo.token);
          sessionStorage.removeItem("impersonateToken");

          return {
            token: userInfo.token,
            userInfo,
          };
        } catch (e) {
          const { toast } = createStandaloneToast();

          toast({
            description: "Nieprawidłowy login lub hasło. Spróbuj ponownie.",
            status: "error",
            duration: 9000,
            variant: "subtle",
          });
          throw e;
        }
      }
    ),
    logout: fromPromise(
      async ({ input }: { input: { onLogout?: () => void } }) => {
        localStorage.removeItem("token");
        sessionStorage.removeItem("impersonateToken");
        input.onLogout?.();
      }
    ),
    changeContext: fromPromise(
      async ({ input }: { input: { contextId: string } }) => {
        await relogin({ contextId: input.contextId });
        const { userInfo } = await getUserInfoQuery();

        return {
          userInfo,
        };
      }
    ),
    impersonateContext: fromPromise(
      async ({ input }: { input: { login: string } }) => {
        const { accessToken } = await impersonate(input.login);

        const token = localStorage.getItem("token");

        localStorage.setItem("token", accessToken);
        sessionStorage.setItem("impersonateToken", token!);

        const { userInfo } = await getUserInfoQuery();

        return {
          userInfo,
        };
      }
    ),
    changeOrganization: fromPromise(
      async ({ input }: { input: { organizationId: string } }) => {
        await changeOrganization({ organizationId: input.organizationId });
        const { userInfo } = await getUserInfoQuery();

        return {
          userInfo,
        };
      }
    ),
    sync: fromPromise(async () => {
      const { userInfo } = await getUserInfoQuery();

      return {
        userInfo,
      };
    }),
    getTokenFromLocalStorage: fromPromise(async () => {
      try {
        const token = getToken();

        if (!token) throw new Error("No token");

        const { userInfo } = await getUserInfoQuery();

        return {
          token,
          userInfo,
        };
      } catch (e) {
        localStorage.removeItem("token");
        throw e;
      }
    }),
  },
}).createMachine({
  id: "context",
  context: {
    token: null,
    userInfo: null,
  },
  initial: "getToken",
  states: {
    getToken: {
      invoke: {
        id: "getTokenFromLocalStorage",
        src: "getTokenFromLocalStorage",
        onDone: {
          target: "loggedIn",
          actions: assign({
            token: ({ event }) => event.output.token,
            userInfo: ({ event }) => event.output.userInfo,
          }),
        },
        onError: {
          target: "loggedOut",
          actions: assign({
            token: null,
            userInfo: null,
          }),
        },
      },
    },
    loggedOut: {
      on: {
        login: {
          target: "logIn",
        },
      },
    },
    logIn: {
      invoke: {
        id: "login",
        src: "login",
        input: ({ event }) => {
          assertEvent(event, "login");
          return { username: event.username, password: event.password };
        },
        onDone: {
          target: "loggedIn",
          actions: assign({
            token: ({ event }) => event.output.token,
            userInfo: ({ event }) => event.output.userInfo,
          }),
        },
        onError: {
          target: "loggedOut",
        },
      },
    },
    loggedIn: {
      on: {
        logout: {
          target: "logOut",
        },
        relog: {
          target: "changeContext",
        },
        impersonate: {
          target: "impersonateContext",
        },
        swapOrganization: {
          target: "changeOrganization",
        },
        sync: {
          target: "sync",
        },
      },
    },
    logOut: {
      invoke: {
        id: "logout",
        src: "logout",
        input: ({ event }) => {
          assertEvent(event, "logout");
          return { onLogout: event.onLogout };
        },
        onDone: {
          target: "loggedOut",
          actions: assign({ token: null, userInfo: null }),
        },
      },
    },
    changeContext: {
      invoke: {
        id: "changeContext",
        src: "changeContext",
        input: ({ event }) => {
          assertEvent(event, "relog");
          return { contextId: event.contextId };
        },
        onDone: {
          target: "loggedIn",
          actions: assign({ userInfo: ({ event }) => event.output.userInfo }),
        },
        onError: {
          target: "loggedIn",
        },
      },
    },
    impersonateContext: {
      invoke: {
        id: "impersonateContext",
        src: "impersonateContext",
        input: ({ event }) => {
          assertEvent(event, "impersonate");
          return { login: event.login };
        },
        onDone: {
          target: "loggedIn",
          actions: assign({ userInfo: ({ event }) => event.output.userInfo }),
        },
        onError: {
          target: "loggedIn",
        },
      },
    },
    changeOrganization: {
      invoke: {
        id: "changeOrganization",
        src: "changeOrganization",
        input: ({ event }) => {
          assertEvent(event, "swapOrganization");
          return { organizationId: event.organizationId };
        },
        onDone: {
          target: "loggedIn",
          actions: assign({ userInfo: ({ event }) => event.output.userInfo }),
        },
        onError: {
          target: "loggedIn",
        },
      },
    },
    sync: {
      invoke: {
        id: "sync",
        src: "sync",
        onDone: {
          target: "loggedIn",
          actions: assign({ userInfo: ({ event }) => event.output.userInfo }),
        },
        onError: {
          target: "loggedIn",
        },
      },
    },
  },
});
