import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  Flex,
  GridItem,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { UserBlockedIcon } from "components/icons/UserBlockedIcon";
import { UserCheckIcon } from "components/icons/UserCheckIcon";
import { UsersIcon } from "components/icons/UsersIcon";

import { useContextsManagersQuery } from "modules/organization/infrastructure/useContextsManagersQuery";

import { DashboardTile } from "../DashboardTile";

export const ContextManagersTile = () => {
  const { data, isLoading } = useContextsManagersQuery();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <>
        <GridItem colSpan={2}>
          <Skeleton height="100%" />
        </GridItem>
      </>
    );
  }

  const activeAdministrators = data?.contextsManagers.filter(
    (context) => context.active
  );

  const inactiveAdministrators = data?.contextsManagers.filter(
    (context) => !context.active
  );

  return (
    <DashboardTile icon={<UsersIcon />} title={t("Administratorzy")}>
      <VStack align="stretch" gap="8" h="100%">
        <SimpleGrid columns={{ base: 2, md: 3 }}>
          <Link to={`/administratorzy?active=true`}>
            <Flex
              flexDir="column"
              alignItems="center"
              cursor="pointer"
              py={2}
              _hover={{ bgColor: "gray.100" }}
              rounded="md"
            >
              <UserCheckIcon size="24px" mb="2" color="green.500" />
              <Text fontSize="lg" fontWeight="semibold">
                {activeAdministrators.length}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {t("Aktywni")}
              </Text>
            </Flex>
          </Link>
          <Link to={`/administratorzy?active=false`}>
            <Flex
              flexDir="column"
              alignItems="center"
              cursor="pointer"
              py={2}
              _hover={{ bgColor: "gray.100" }}
              rounded="md"
            >
              <UserBlockedIcon size="24px" mb="2" color="red.500" />
              <Text fontSize="lg" fontWeight="semibold">
                {inactiveAdministrators.length}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {t("Nieaktywni")}
              </Text>
            </Flex>
          </Link>
          <Link to={`/administratorzy`}>
            <Flex
              flexDir="column"
              alignItems="center"
              cursor="pointer"
              py={2}
              _hover={{ bgColor: "gray.100" }}
              rounded="md"
            >
              <UsersIcon size="24px" mb="2" color="purple.500" />
              <Text fontSize="lg" fontWeight="semibold">
                {data?.contextsManagers.length}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {t("Wszystkie")}
              </Text>
            </Flex>
          </Link>
        </SimpleGrid>
        <Button
          mt="auto"
          colorScheme="purple"
          onClick={() => navigate("/administratorzy")}
          variant="outline"
        >
          {t("Przeglądaj listę administratorów")}
        </Button>
      </VStack>
    </DashboardTile>
  );
};
