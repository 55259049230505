import { useMutation } from "@tanstack/react-query";

import { createInstance } from "utils/http";

interface MutationVariables {
  verificationId: string;
  type: "pdf" | "html" | "json";
}

export const useDownloadEPW = () => {
  return useMutation({
    mutationKey: ["epw"],
    mutationFn: async (variables: MutationVariables) => {
      const language = "pl";
      const client = createInstance();
      const data = await client.get(
        `signatureVerification/${variables.verificationId}/report?format=${variables.type}&lang=${language}`
      );

      const fileName = data.headers.get("file-name")!;
      const decodedFileName = decodeURIComponent(fileName);

      const file = new Blob([await data.arrayBuffer()], {
        type: "application/octet-stream",
      });

      const url = window.URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${decodedFileName}`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
  });
};
