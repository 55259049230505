import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { LinkIcon } from "components/icons/LinkIcon";

import { AssignManagerModal } from "./AssignManagerModal";

interface IProps {
  contextId: string;
}

export const AssignManagerButton = ({ contextId }: IProps) => {
  const id = `assign-manager-${contextId}`;

  const onOpen = useOpenDialog(id);

  const label = t("Powiąż z podmiotem");

  return (
    <>
      <AssignManagerModal id={id} contextId={contextId} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="assign-manager-button"
          onClick={onOpen}
          aria-label={label}
          icon={<LinkIcon />}
        />
      </Tooltip>
    </>
  );
};
