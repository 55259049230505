import ky, { Options } from "ky";

import { getToken } from "utils/getToken";

import { httpEmitter } from "./HttpEmitter";
import { Client } from "./createClient";
import { withHost } from "./withHost";

export const createInstance = (options?: Options) => {
  const token = getToken();

  return ky.create({
    prefixUrl: withHost(""),
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      ...options?.headers,
    },
    retry: 0,
    timeout: 300_000,
    hooks: {
      afterResponse: [
        (_request, _options, response) => {
          httpEmitter.publish("afterResponse", { response });
        },
      ],
    },
  });
};

export const client: Client<Options> = {
  async get(url, options) {
    const instance = createInstance(options);
    return instance.get(url, options).json();
  },
  async post(url, { body, ...options } = { body: undefined }) {
    const instance = createInstance(options);
    return instance.post(url, { ...options, json: body }).json();
  },
  async put(url, { body, ...options } = { body: undefined }) {
    const instance = createInstance(options);
    return instance.put(url, { ...options, json: body }).json();
  },
  async patch(url, { body, ...options } = { body: undefined }) {
    const instance = createInstance(options);
    return instance.patch(url, { ...options, json: body }).json();
  },
  async delete(url, { body, ...options } = { body: undefined }) {
    const instance = createInstance(options);
    return instance.delete(url, { ...options, json: body }).json();
  },
};
