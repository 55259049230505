import { useParams } from "react-router-dom";

import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Spacer,
  Td,
  Text,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { UserContext } from "modules/organization/application/types/IOrganizationContext";
import { useOrganizationContextsQuery } from "modules/organization/infrastructure/useOrganizationContextsQuery";

import { AddUserButton } from "../AddUserButton";
import { AddUserModal } from "../AddUserButton/AddUserModal";
import { EditUserButton } from "./EditUserButton";
import { EditUserPasswordButton } from "./EditUserPasswordButton";
import { RemoveUserButton } from "./RemoveUserButton";
import { UsersFilter } from "./UsersFilter";

const columnHelper = createColumnHelper<UserContext>();

const columns = [
  columnHelper.accessor("name", {
    header: () => t("Nazwa konta"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="user-name">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("details.login", {
    header: () => t("Login"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="user-login">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Data utworzenia"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <RemoveUserButton user={info.row.original} />
          <EditUserButton user={info.row.original} />
          <EditUserPasswordButton contextId={info.getValue()} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  contexts: UserContext[];
  isLoading: boolean;
}

const UsersTableConnected = ({ contexts, isLoading }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (contexts.length === 0) {
    return <TableEmptyState title={t("Lista użytkowników jest pusta")} />;
  }

  const sortedRecords = reverse(
    sortBy(contexts, function (data) {
      return new Date(data.createdAt);
    })
  );

  return (
    <Table<UserContext>
      data={sortedRecords}
      columns={columns}
      globalSearchParam="usersSearch"
    />
  );
};

export const UsersTable = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useOrganizationContextsQuery(organizationId!);

  return (
    <>
      <AddUserModal />
      <Card id="users" variant="sawpe">
        <CardHeader>
          <VStack align="stretch">
            <HStack spacing="6" wrap="wrap">
              <Heading fontSize="lg">{t("Użytkownicy")}</Heading>
              <AddUserButton organizationId={organizationId!} />
              <Spacer />
              <UsersFilter />
            </HStack>
            <Text fontSize="sm" color="gray.600">
              {t(
                "Konta użytkowników umożliwiają korzystanie z usług weryfikacji podpisów i pieczęci elektronicznych. Użytkownikom przekaż stworzone przez siebie login i hasło logowania. Możesz także włączyć opcję samodzielnej zmiany hasła przez użytkowników."
              )}
            </Text>
          </VStack>
        </CardHeader>
        <CardBody>
          <UsersTableConnected
            contexts={
              data?.contexts.filter(
                ({ type }) => type === "context_user"
              ) as UserContext[]
            }
            isLoading={isLoading}
          />
        </CardBody>
      </Card>
    </>
  );
};
