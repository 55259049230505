import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { DeleteIcon } from "components/icons/DeleteIcon";

import { UserContext } from "modules/organization/application/types/IOrganizationContext";

import { RemoveUserConfirm } from "./RemoveUserConfirm";

interface IProps {
  user: UserContext;
}

export const RemoveUserButton = ({ user }: IProps) => {
  const id = `remove-user-${user.id}`;
  const onOpen = useOpenDialog(id);
  const label = t("Usuń");

  return (
    <>
      <RemoveUserConfirm id={id} contextId={user.id} name={user.name} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="remove-user-password"
          onClick={() => onOpen()}
          aria-label={label}
          icon={<DeleteIcon />}
        />
      </Tooltip>
    </>
  );
};
