import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IVerificationProcess } from "../application/types/IVerificationProcess";

const query = (contextId: string) => {
  return {
    queryKey: ["verificationProcess", "prepare"],
    queryFn: () =>
      client.get<{ verificationProcess: IVerificationProcess }>(
        `contexts/${contextId}/verificationProcess/prepare`
      ),
    refetchOnWindowFocus: false,
  };
};

export const useVerificationProcessQuery = (contextId: string) => {
  return useQuery(query(contextId));
};

export const getVerificationProcessMock = () => {
  return http.get(
    withHost("contexts/:contextId/verificationProcess/prepare"),
    () => {
      return HttpResponse.json<{ verificationProcess: IVerificationProcess }>({
        verificationProcess: {
          id: "32fc75a7-e3ec-42a9-9e1e-2a44e5f21f0b",
          contextId: "00000000-0000-0000-0000-000000000001",
          tariffId: "00000000-0000-0000-0000-100000000003",
          allowDispositionDownload: true,
          filesVerificationLimit: {
            type: "unlimited",
            limit: null,
          },
          verificationId: "asddsa-asd",
          status: "prepared",
          createdAt: "2024-07-02T11:15:16.740+00:00",
          updatedAt: null,
        },
      });
    }
  );
};
