import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { TariffTypes } from "modules/tariffs/application/types/ITariff";

import { Recipient } from "../application/Recipient";

const query = (tariffTypes: TariffTypes[]) => {
  const params = new URLSearchParams();
  tariffTypes.forEach((tariffType, index) => {
    params.append(`tariffTypes[${index}]`, tariffType);
  });

  return {
    queryKey: ["notifications", "recipients"],
    queryFn: () =>
      client.get<{ recipients: Recipient[] }>(
        `notifications/recipients/tariffTypes`,
        {
          searchParams: params.toString(),
        }
      ),
  };
};

export const useRecipientsQuery = (tariffTypes: TariffTypes[]) => {
  return useQuery(query(tariffTypes));
};

export const getRecipientsMock = (recipients: Recipient[] = []) => {
  return http.get(withHost("notifications/recipients/tariffTypes"), () => {
    return HttpResponse.json<{ recipients: Recipient[] }>({
      recipients: [...recipients],
    });
  });
};
