import { useParams } from "react-router-dom";

import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Spacer,
  Td,
  Text,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { ManagerContext } from "modules/organization/application/types/IOrganizationContext";
import { useOrganizationContextsQuery } from "modules/organization/infrastructure/useOrganizationContextsQuery";

import { AddManagerBtn } from "./AddManagerBtn";
import { EditManagerBtn } from "./EditManagerBtn";
import { ManagersFilter } from "./ManagersFilter";
import { RemoveManagerBtn } from "./RemoveManagerBtn";

const columnHelper = createColumnHelper<ManagerContext>();

const columns = (onlyOneActive: boolean, organizationId: string) => [
  columnHelper.accessor("name", {
    header: () => t("Nazwa konta"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="manager-name">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("details.email", {
    header: () => t("E-mail"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="manager-email">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("details.phone", {
    header: () => t("Telefon"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="manager-phone">
        {info.getValue()}
      </Td>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Data utworzenia"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => {
      return (
        <Td whiteSpace="normal" isNumeric>
          <ButtonGroup>
            <RemoveManagerBtn
              manager={info.row.original}
              onlyOneActive={onlyOneActive}
              organizationId={organizationId}
            />
            <EditManagerBtn manager={info.row.original} />
          </ButtonGroup>
        </Td>
      );
    },
  }),
];

interface IProps {
  contexts: ManagerContext[];
  isLoading: boolean;
}

const ManagersTableConnected = ({ contexts, isLoading }: IProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();

  if (isLoading) {
    return <TableLoadingState />;
  }

  if (contexts.length === 0) {
    return <TableEmptyState title={t("Lista administratorów jest pusta")} />;
  }

  const onlyOneActive = contexts.filter(({ active }) => active).length === 1;
  const sortedRecords = reverse(
    sortBy(contexts, function (data) {
      return new Date(data.createdAt);
    })
  );

  return (
    <Table<ManagerContext>
      data={sortedRecords}
      columns={columns(onlyOneActive, organizationId!)}
      globalSearchParam="managersSearch"
    />
  );
};

export const ManagersTable = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useOrganizationContextsQuery(organizationId!);

  const contextManagers = data?.contexts.filter(
    ({ type }) => type === "context_manager"
  ) as ManagerContext[];

  return (
    <Card id="managers" variant="sawpe">
      <CardHeader>
        <VStack align="stretch">
          <HStack spacing="6" wrap="wrap">
            <Heading fontSize="lg">{t("Administratorzy")}</Heading>
            <AddManagerBtn
              organizationId={organizationId!}
              isLoading={isLoading}
              managersCount={contextManagers?.length}
            />
            <Spacer />
            <ManagersFilter />
          </HStack>
          <Text fontSize="sm" color="gray.600">
            {t(
              "Administratorzy mogą zarządzać danymi Twojego podmiotu. Możesz dodać kolejnych administratorów którzy będą Cię wspomagać w zarządzaniu Twoim podmiotem. W każdym momencie czasu możesz dezaktywować konta administratorów, jednakże zawsze musi istnieć co najmniej 1 aktywne konto administratora."
            )}
          </Text>
        </VStack>
      </CardHeader>
      <CardBody>
        <ManagersTableConnected
          contexts={contextManagers}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
