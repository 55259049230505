import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  contextId: string;
  organizationId: string;
}

export const useAssignContextManager = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["assign-context-manager"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(
        `contextsManagers/contexts/${variables.contextId}/assignOrganization`,
        {
          body: {
            organizationId: variables.organizationId,
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["contextsManagers"],
      });
    },
  });
};
