import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import { client } from "utils/http";

import { TariffTypes } from "../application/types/ITariff";

interface MutationVariables {
  type: TariffTypes;
  validFrom: string;
  validTo: string;
  withApiAccess: boolean | null;
  personalDataClause: boolean | null;
}

export const useEditTariff = (tariffId: string) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["edit-tariff"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(`organizations/${organizationId}/tariffs/${tariffId}`, {
        body: {
          ...variables,
          validFrom: dayjs(variables.validFrom).toISOString(),
          validTo: dayjs(variables.validTo)
            .add(1, "day")
            .subtract(1, "second")
            .toISOString(),
        },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "tariffs"],
      });
    },
  });
};
