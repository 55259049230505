import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { Change } from "modules/changelog/application/types/Change";

export const getTrackedChange = (changelog: Change[]): Change | null => {
  const publishedChanges = changelog.filter(({ publishedAt }) => publishedAt);

  const sortedRecords = reverse(
    sortBy(publishedChanges, function (changelog) {
      return new Date(changelog.publishedAt!);
    })
  );

  return sortedRecords[0] || null;
};
