import { Link } from "react-router-dom";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { OrganizationStatus } from "modules/organization/application/types/IOrganization";

import { OrganizationStatusBadge } from "../OrganizationStatusBadge";
import { UnassignedOrganizationButton } from "./UnassignedOrganizationButton";

interface IOrganization {
  id: string;
  name: string;
  organizationStatus: OrganizationStatus;
  isLastManager: boolean;
}

interface IProps {
  contextId: string;
  contextName: string;
  organizations: IOrganization[];
}

export const ManagedOrganizations = ({
  organizations,
  contextId,
  contextName,
}: IProps) => {
  if (organizations.length === 1) {
    return (
      <Organization
        {...organizations[0]}
        contextId={contextId}
        contextName={contextName}
        isLastOrganization
      />
    );
  }

  return (
    <Accordion allowToggle w="400px">
      <AccordionItem borderTop="none">
        <h2>
          <AccordionButton px={0}>
            <Text fontWeight="500" flex="1" fontSize="sm" textAlign="left">
              {t("Powiązane podmioty ({{count}})", {
                count: organizations.length,
              })}
            </Text>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} px={0}>
          <VStack align="stretch" spacing="4">
            {organizations.map((organization) => (
              <Organization
                key={organization.id}
                contextId={contextId}
                contextName={contextName}
                {...organization}
              />
            ))}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

interface OrgProps extends IOrganization {
  contextId: string;
  contextName: string;
  isLastOrganization?: boolean;
}

const Organization = ({
  id,
  name,
  organizationStatus,
  contextId,
  contextName,
  isLastManager,
}: OrgProps) => {
  return (
    <HStack
      justifyContent="space-between"
      w="400px"
      data-testid="accordion-organization"
    >
      <Link to={`/${id}`}>
        <Text
          isTruncated
          maxW="260px"
          title={name}
          _hover={{ textDecoration: "underline" }}
        >
          {name}
        </Text>
      </Link>
      <HStack>
        <OrganizationStatusBadge status={organizationStatus} />
        <UnassignedOrganizationButton
          contextId={contextId}
          contextName={contextName}
          organizationName={name}
          organizationId={id}
          isLastManager={isLastManager}
        />
      </HStack>
    </HStack>
  );
};
