import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

import { BaseOrganization } from "../application/types/BaseOrganization";

export interface Company extends BaseOrganization {
  type: "company" | "public_institution" | "other";
}

export const useAddCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["organizations", "company"],
    mutationFn: (body: Company) =>
      client.post("organizations/company", {
        body: {
          ...body,
          contextManager: body.contextManagerId ? null : body.contextManager,
        },
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
    },
  });
};
