import { useState } from "react";

import {
  Button,
  ButtonGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";
import { HTTPError } from "ky";

import { Modal } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useAssignContextManager } from "modules/organization/infrastructure/useAssignContextManager";

import { OrganizationSearch } from "./OrganizationSearch";

interface IProps {
  id: string;
  contextId: string;
}

export const AssignManagerModal = ({ id, contextId }: IProps) => {
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const { mutateAsync: assignManager } = useAssignContextManager();
  const isMutating = useIsMutating({
    mutationKey: ["assign-context-manager"],
  });

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Powiąż z podmiotem")}</ModalHeader>
              <ModalCloseButton />

              <ModalBody as={VStack} align="stretch" spacing={6}>
                <OrganizationSearch
                  value={organizationId}
                  onChange={setOrganizationId}
                />
              </ModalBody>

              <ModalFooter>
                <ButtonGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Anuluj")}</Button>
                  <Button
                    colorScheme="purple"
                    isLoading={!!isMutating}
                    onClick={async () => {
                      try {
                        if (organizationId)
                          await assignManager({
                            contextId,
                            organizationId,
                          });
                        setOrganizationId(null);
                        onClose();
                      } catch (error) {
                        if (error instanceof HTTPError) {
                          const errorJson = await error.response.json<{
                            message: string;
                          }>();

                          if (
                            errorJson.message ===
                            "context.error.contextManagerAlreadyAssigned"
                          ) {
                            return toastWithError({
                              description: t(
                                "Admin już jest przypisany do tego podmiotu."
                              ),
                              error,
                            });
                          }
                        }

                        toastWithError({
                          error,
                        });
                      }
                    }}
                  >
                    {t("Powiąż")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
