import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

export const useRemoveUser = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();
  const { send } = ContextMachineContext.useActorRef();

  return useMutation({
    mutationKey: ["remove-user"],
    mutationFn: (contextId: string) => {
      return client.delete(
        `organizations/${organizationId}/contexts/${contextId}`
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
      send({ type: "sync" });
    },
  });
};
