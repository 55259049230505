import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { PlusIcon } from "components/icons/PlusIcon";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { useOpenAddUserModal } from "./AddUserModal";

interface IProps {
  organizationId: string;
}

export const AddUserButton = ({ organizationId }: IProps) => {
  const onOpen = useOpenAddUserModal();
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);

  if (isLoading) {
    return null;
  }

  if (
    data?.organization.status !== "ACTIVE" &&
    data?.organization.status !== "NEW"
  ) {
    return null;
  }

  return (
    <>
      <Button
        size="sm"
        leftIcon={<PlusIcon />}
        colorScheme="purple"
        onClick={onOpen}
      >
        {t("Dodaj użytkownika")}
      </Button>
    </>
  );
};
