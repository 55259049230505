import { Button, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { t } from "i18next";

import { BellIcon } from "components/icons/BellIcon";

import { useOpenChangelogModal } from "modules/changelog/presentation/ChangelogModal";

import { NotReadDot } from "./NotReadDot";

export const NotificationButton = () => {
  const type = useBreakpointValue({
    base: undefined,
    lg: IconButton,
  });

  const onOpen = useOpenChangelogModal();

  return (
    <NotReadDot
      top={{ base: "3px", lg: "10px" }}
      right={{ base: "115px", lg: "15px" }}
    >
      {(turnOff) => {
        return (
          <Button
            w="100%"
            as={type}
            title={t("Historia zmian")}
            variant={{ base: "link", lg: "ghost" }}
            aria-label="system-events"
            icon={<BellIcon height="20px" />}
            leftIcon={<BellIcon height="24px" />}
            background="transparent"
            onClick={() => {
              onOpen();
              turnOff?.();
            }}
          >
            {t("Historia zmian")}
          </Button>
        );
      }}
    </NotReadDot>
  );
};
