import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useUnassignContextManager } from "modules/organization/infrastructure/useUnassignContextManager";

interface IProps {
  id: string;
  name: string;
  contextId: string;
  organizationId: string;
}

export const RemoveManagerConfirm = ({
  id,
  name,
  contextId,
  organizationId,
}: IProps) => {
  const { mutateAsync: remove } = useUnassignContextManager(organizationId);
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );
  const { send } = ContextMachineContext.useActorRef();

  return (
    <AlertDialog
      id={id}
      title={t("Usuń administratora")}
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t("Czy na pewno chcesz usnąć administratora {{accName}}?", {
              accName: name,
            })}
          </Text>
        </VStack>
      }
      confirmLabel={t("Usuń")}
      onConfirm={async () => {
        try {
          await remove({
            contextId,
          });
          if (contextId === userInfo?.currentlySelectedContext?.id) {
            send({ type: "logout", onLogout: () => window.location.reload() });
          }
        } catch (error) {
          toastWithError({
            error,
          });
        }
      }}
      colorScheme="purple"
      size="xl"
    />
  );
};
