import { ComponentProps } from "react";

import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { DeleteIcon } from "components/icons/DeleteIcon";

import { UnassignedOrganizationConfirm } from "./UnassignedOrganizationConfirm";

type IProps = Omit<
  ComponentProps<typeof UnassignedOrganizationConfirm>,
  "id"
> & {
  isLastManager: boolean;
};

export const UnassignedOrganizationButton = ({
  isLastManager,
  ...props
}: IProps) => {
  const id = `unassign-manager-${props.contextId}-${props.organizationName}`;

  const onOpen = useOpenDialog(id);

  const label = t("Odwiąż");

  if (isLastManager) {
    return (
      <Tooltip
        label={"W podmiocie musi pozostać minimum jeden aktywny administrator."}
        aria-label={label}
      >
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="unassign-manager-button"
          aria-label={label}
          isDisabled
          icon={<DeleteIcon />}
        />
      </Tooltip>
    );
  }

  return (
    <>
      <UnassignedOrganizationConfirm id={id} {...props} />
      <Tooltip label={label} aria-label={label}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="unassign-manager-button"
          onClick={onOpen}
          aria-label={label}
          icon={<DeleteIcon />}
        />
      </Tooltip>
    </>
  );
};
