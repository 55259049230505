import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { HStack, SimpleGrid, VStack } from "@chakra-ui/react";
import { settings } from "settings/getSettings";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { OrganizationPreviewWidget } from "modules/organization/presentation/OrganizationPreviewWidget";
import { TariffUsageWidget } from "modules/tariffs/presentation/TariffUsageWidget";
import { StartVerification } from "modules/verification/presentation/StartVerification";
import { StartVerification as StartVerificationV2 } from "modules/verification/presentation/StartVerificationV2";

export const Verification = withTranslation()(() => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data } = useOrganizationByIdQuery(organizationId!);

  const isNewVerificationEnabled = settings.IS_NEW_VERIFICATION_ENABLED;

  return (
    <VStack align="stretch" gap="8">
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="8">
        <OrganizationPreviewWidget />
        <TariffUsageWidget />
      </SimpleGrid>
      <HStack gap="8" align="start">
        <VStack align="stretch" w="100%" gap="6">
          {!isNewVerificationEnabled &&
          data?.organization.tariffs?.current?.type === "E" ? (
            <StartVerification />
          ) : (
            <StartVerificationV2 />
          )}
        </VStack>
      </HStack>
    </VStack>
  );
});
