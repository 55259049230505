import { useParams } from "react-router-dom";

import { Box, HStack, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { HelpIcon } from "components/icons/HelpIcon";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { InterpretationRequestButton } from "./InterpretationRequestButton";

interface IProps {
  verificationId: string | null | undefined;
}

export const ResultInterpretationRequest = ({ verificationId }: IProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data } = useOrganizationByIdQuery(organizationId!);

  const tariffType = data?.organization.tariffs.current?.type;

  if (!verificationId) return null;
  if (!tariffType || tariffType === "S") return null;

  return (
    <VStack align="stretch" spacing={4} px="2" py="3" mt={8}>
      <Heading fontSize="lg">
        {t("Pomoc w interpretacji wyniku weryfikacji")}
      </Heading>
      <HStack
        spacing="130px"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <VStack alignItems="flex-start" justifyContent="space-between">
          <Box>
            <Text>{t("Masz problem z interpretacją wyniku weryfikacji?")}</Text>
            {tariffType === "E" && (
              <Text>
                {t(
                  "Korzystasz z naszych usług w ramach pakietu Enterprise. Możesz zatem skontaktować się z nami telefonicznie pod numerem telefonu"
                )}{" "}
                <Link
                  href="tel:+48 58 712 60 20"
                  fontWeight={600}
                  color="purple.500"
                >
                  +48 58 712 60 20
                </Link>
                {". "}
              </Text>
            )}
            <Text>
              {t(
                "Odpowiedzi na najcześciej zadawane pytania znajdziesz w sekcji"
              )}{" "}
              <Link
                href="https://weryfikacjapodpisu.pl/faq/"
                fontWeight={600}
                color="purple.500"
              >
                {t("FAQ")}
              </Link>
              {". "}
              {t(
                "Jeśli dalej potrzebujesz pomocy - wypełnij formularz, na pewno zareagujemy."
              )}
            </Text>
          </Box>
          <InterpretationRequestButton verificationId={verificationId} />
        </VStack>
        <HelpIcon
          h="150px"
          w="150px"
          color="purple.500"
          display={{ base: "none", md: "block" }}
        />
      </HStack>
    </VStack>
  );
};
