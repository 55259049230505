import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Td,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { IVerificationHistoryRecord } from "modules/verification/application/types/IVerificationHistoryRecord";
import { useContextVerificationsHistoryQuery } from "modules/verification/infrastructure/useContextVerificationsHistoryQuery";
import { useVerificationsHistoryQuery } from "modules/verification/infrastructure/useVerificationsHistoryQuery";

import { InterpretationRequestBtn } from "./InterpretationRequestBtn";
import { VerificationHistoryFilter } from "./VerificationHistoryFilter";
import { VerificationLink } from "./VerificationLink";

const columnHelper = createColumnHelper<IVerificationHistoryRecord>();

const columns = [
  columnHelper.accessor(
    (row) => (row.type === "PANEL" ? row.source?.contextName : row.type),
    {
      id: "verification source",
      header: () => t("Źródło weryfikacji"),
      cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
    }
  ),
  columnHelper.accessor("createdAt", {
    header: () => t("Data"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("source.ip", {
    header: () => t("IP"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("verificationId", {
    header: () => t("Identyfikator weryfikacji"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="verification-link">
        <VerificationLink verificationId={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("verificationId", {
    id: "interpretation",
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <InterpretationRequestBtn verificationId={info.getValue()} />
      </Td>
    ),
  }),
];

interface IProps {
  verifications: IVerificationHistoryRecord[];
  isLoading: boolean;
}

const VerificationsHistoryTableConnected = ({
  verifications,
  isLoading,
}: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (verifications.length === 0) {
    return (
      <TableEmptyState title={t("Nie wykonano jeszcze żadnych weryfikacji")} />
    );
  }

  const sortedRecords = reverse(
    sortBy(verifications, function (verification) {
      return new Date(verification.createdAt);
    })
  );

  return (
    <Table<IVerificationHistoryRecord> data={sortedRecords} columns={columns} />
  );
};

const ContextVerificationsHistoryTable = ({
  contextId,
}: {
  contextId: string;
}) => {
  const { data, isLoading } = useContextVerificationsHistoryQuery(contextId);

  return (
    <VerificationsHistoryTableConnected
      verifications={data?.verifications}
      isLoading={isLoading}
    />
  );
};

const OrganizationVerificationsHistoryTable = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useVerificationsHistoryQuery(organizationId!);

  return (
    <VerificationsHistoryTableConnected
      verifications={data?.verifications}
      isLoading={isLoading}
    />
  );
};

export const VerificationsHistoryTable = () => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  return (
    <Card variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Wykonane weryfikacje")}</Heading>
        <Spacer />
        <VerificationHistoryFilter />
      </CardHeader>
      <CardBody>
        {userInfo?.currentlySelectedContext?.type === "context_user" ? (
          <ContextVerificationsHistoryTable
            contextId={userInfo?.currentlySelectedContext.id}
          />
        ) : (
          <OrganizationVerificationsHistoryTable />
        )}
      </CardBody>
    </Card>
  );
};
