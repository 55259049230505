import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  Heading,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";
import { settings } from "settings/getSettings";
import {
  ConfigProvider,
  Dropzone,
  EPW,
  IVerificationResult,
  SearchBar,
  VerificationResult,
} from "verification-widget";

import { toastWithError } from "components/ErrorHandling/toastWithError";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { useDownloadEPW } from "modules/verification/infrastructure/useDownloadEPW";
import { useListenForVerificationStatus } from "modules/verification/infrastructure/useListenForVerificationStatus";
import { useSearch } from "modules/verification/infrastructure/useSearch";
import { useVerify } from "modules/verification/infrastructure/useVerify";

import { ResultInterpretationRequest } from "../ResultInterpretationRequest";

interface IProps {
  isLoading: boolean;
  inactive: boolean;
  contextId: string;
  filesLimit: number | null;
  allowDispositionDownload: boolean;
  mercureTopic: string;
}

const StartVerificationConnected = ({
  isLoading,
  inactive,
  contextId,
  filesLimit,
  allowDispositionDownload,
  mercureTopic,
}: IProps) => {
  const [searchParams] = useSearchParams();
  const [verification, setVerification] = useState<IVerificationResult | null>(
    null
  );

  const [listenForVerification, isMutating] = useListenForVerificationStatus();
  const { mutateAsync: verify } = useVerify(contextId);
  const { mutateAsync: downloadEPW } = useDownloadEPW();
  const { mutateAsync: search } = useSearch();

  useEffect(() => {
    const verificationId = searchParams.get("searchVerificationId");
    if (verificationId) {
      search(verificationId)
        .then((result) => {
          setVerification(result);
        })
        .catch((error) => {
          if (error instanceof HTTPError && error.response.status === 404) {
            toastWithError({
              error,
              description: t("Nie udało się odnaleźć żadnego wyniku."),
              duration: 12_000,
            });
            return;
          }

          toastWithError({ error });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <VStack align="stretch" maxW="1200px" mx="auto" pb={16}>
        <Skeleton height="300px" />
        <Skeleton height="50px" />
      </VStack>
    );
  }

  if (inactive) {
    return (
      <Alert
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {t("Brak aktywnego pakietu")}
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {t("Skontaktuj się z administratorem systemu.")}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <VStack align="stretch" maxW="1200px" mx="auto" pb={16}>
      <Dropzone
        fileLimit={filesLimit!}
        isMutating={isMutating}
        onVerify={async (files: File[], reset: () => void) => {
          try {
            const { verificationProcess } = await verify({
              files,
            });

            listenForVerification(
              mercureTopic,
              verificationProcess.verificationId,
              (result) => {
                setVerification(result);
                reset();
              }
            );
          } catch (error) {
            toastWithError({ error });
          }
        }}
      />
      <SearchBar
        onSearch={async (value) => {
          try {
            const result = await search(value);
            setVerification(result);
          } catch (error) {
            if (error instanceof HTTPError && error.response.status === 404) {
              toastWithError({
                error,
                description: t("Nie udało się odnaleźć żadnego wyniku."),
              });
              return;
            }

            toastWithError({ error });
          }
        }}
      />
      {verification && (
        <>
          <EPW
            verificationId={verification.idRequest}
            allowDownload={allowDispositionDownload}
            onClick={async (type) => {
              try {
                await downloadEPW({
                  verificationId: verification.idRequest,
                  type,
                });
              } catch (error) {
                toastWithError({ error });
              }
            }}
          />
          <VerificationResult result={verification} />
          <ResultInterpretationRequest
            verificationId={verification.idRequest}
          />
        </>
      )}
    </VStack>
  );
};

export const StartVerification = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  if (!userInfo?.currentlySelectedContext?.id) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <VStack align="stretch">
          <Heading fontSize="lg" px="2" py="3">
            {t("Weryfikuj pliki")}
          </Heading>
        </VStack>
        <ConfigProvider value={{ hostApi: settings.API_HOST! }}>
          <StartVerificationConnected
            isLoading={isLoading}
            inactive={!data?.organization.tariffs.current?.isActive}
            contextId={userInfo?.currentlySelectedContext?.id}
            filesLimit={
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              data?.organization.tariffs.current?.filesVerificationLimit.limit!
            }
            allowDispositionDownload={
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              data?.organization.tariffs.current?.allowDispositionDownload!
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            mercureTopic={data?.organization.tariffs.current?.topic!}
          />
        </ConfigProvider>
      </CardBody>
    </Card>
  );
};
