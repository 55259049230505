import { client } from "utils/http";

export const impersonate = async (login: string) => {
  return client.put<{ accessToken: string }, { login: string }>(
    `auth/impersonate`,
    {
      body: { login },
    }
  );
};
