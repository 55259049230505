import { useMutation } from "@tanstack/react-query";
import { IVerificationResult } from "verification-widget";

import { client } from "utils/http";

export const useSearch = () => {
  return useMutation({
    mutationKey: ["search"],
    mutationFn: async (verificationId: string) => {
      return client.get<IVerificationResult>(
        `signatureVerification/${verificationId}/json`,
        {
          headers: {
            Authorization: undefined,
          },
        }
      );
    },
  });
};
