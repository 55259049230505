import { useState } from "react";
import { useParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";
import { settings } from "settings/getSettings";
import { IVerificationResult } from "verification-widget";

import { useSearch } from "./useSearch";

type VerificationStatus = {
  status: "PREPARED" | "FINISHED" | "FAILED";
  verificationId: string;
  verificationProcessId: string;
};

export const useListenForVerificationStatus = () => {
  const [isMutating, setIsMutating] = useState<boolean>(false);

  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();
  const { mutateAsync: search } = useSearch();

  const listenForVerificationStatus = (
    topic: string,
    verificationId: string,
    callback: (result: IVerificationResult) => void
  ) => {
    try {
      setIsMutating(true);
      const url = new URL(`${settings.MERCURE}/.well-known/mercure`);
      url.searchParams.append("topic", topic);

      const eventSource = new EventSource(url);

      eventSource.onmessage = async (event: MessageEvent<string>) => {
        try {
          const message = JSON.parse(event.data) as VerificationStatus;

          if (
            message.status === "FAILED" &&
            verificationId === message.verificationId
          ) {
            eventSource.close();
            setIsMutating(false);
            throw new Error("Verification failed");
          }

          if (
            message.status === "FINISHED" &&
            verificationId === message.verificationId
          ) {
            const result = await search(message.verificationId);

            eventSource.close();
            queryClient.invalidateQueries({
              queryKey: ["organizations", organizationId, "details"],
            });
            setIsMutating(false);
            callback(result);
          }
        } catch (error) {
          eventSource.close();
          setIsMutating(false);
          throw error;
        }
      };
    } catch (error) {
      setIsMutating(false);
      throw error;
    }
  };

  return [listenForVerificationStatus, isMutating] as const;
};
