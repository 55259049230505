import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";

import { createFormData } from "utils/createFormData";
import { withHost } from "utils/http";

import { IVerificationProcess } from "../application/types/IVerificationProcess";

interface MutationVariables {
  files: File[];
}

export const useVerify = (contextId: string) => {
  return useMutation({
    mutationKey: ["verify"],
    mutationFn: async (variables: MutationVariables) => {
      const formData = createFormData({
        timezone: dayjs().format("Z").replace(":", ""),
      });

      variables.files.forEach((file, index) =>
        formData.append(`files[${index}]`, file)
      );

      const response = await fetch(
        withHost(`contexts/${contextId}/signatureVerification`),
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw await response.json();
      }

      return response.json() as Promise<{
        verificationProcess: IVerificationProcess;
        mercure: {
          topicString: string;
        };
      }>;
    },
  });
};
