import { Progress, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { formatNumber } from "utils/i18n/formatNumber";

import { IUsageLimit } from "modules/tariffs/application/types/ITariff";

export const UsageLimit = ({
  type,
  totalLimit,
  apiVerificationCount,
  defaultVerificationCount,
}: IUsageLimit) => {
  const totalCount = apiVerificationCount + defaultVerificationCount;

  if (type === "UNLIMITED") {
    return (
      <Text>
        {formatNumber(totalCount)}/{t("Bez limitu")}
      </Text>
    );
  }

  const percentageUsage = (totalCount / totalLimit!) * 100;

  return (
    <VStack align="stretch" gap={2}>
      <Text>
        {formatNumber(totalCount)}/{formatNumber(totalLimit!)}
      </Text>
      <Progress
        hasStripe
        value={percentageUsage}
        size="sm"
        colorScheme="purple"
        height="4px"
      />
    </VStack>
  );
};
