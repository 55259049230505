import { useNavigate } from "react-router-dom";

import { MenuItem } from "@chakra-ui/react";
import { t } from "i18next";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

export const ExitImpersonateMenuItem = () => {
  const { send } = ContextMachineContext.useActorRef();
  const navigate = useNavigate();

  return (
    <MenuItem
      onClick={() => {
        const impersonateToken = sessionStorage.getItem("impersonateToken");
        sessionStorage.removeItem("impersonateToken");
        localStorage.setItem("token", impersonateToken!);

        send({ type: "sync" });
        navigate("/");
      }}
    >
      {t("Wyjdź z trybu impersonate")}
    </MenuItem>
  );
};
