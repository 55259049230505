import { useEffect, useState } from "react";

import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { ClearIcon } from "components/icons/ClearIcon";

import { useContextsManagersQuery } from "modules/organization/infrastructure/useContextsManagersQuery";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";

import { OrganizationStatusBadge } from "../../OrganizationStatusBadge";

interface IProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const MAX_MANAGERS_COUNT = 50;

export const OrganizationSearch = ({ value, onChange }: IProps) => {
  const [search, setSearch] = useState<string>("");
  const [focused, setFocused] = useState(false);
  const {
    data: { contextsManagers },
  } = useContextsManagersQuery();
  const { data } = useOrganizationsQuery();

  // posortowane od najnowszych
  const options = reverse(
    sortBy(
      data?.organizations.filter((organization) => {
        if (search === "") {
          return true;
        }

        return (
          organization.details.displayName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          organization.details.email
            .toLowerCase()
            .includes(search.toLowerCase())
        );
      }),
      function (data) {
        return new Date(data.createdAt);
      }
    ).map((organization) => {
      return {
        ...organization,
        managersCountExcited:
          contextsManagers?.filter(({ organizations }) =>
            organizations.find(({ id }) => id === organization.id)
          ).length >= MAX_MANAGERS_COUNT,
      };
    })
  );

  const setManagerName = () => {
    setSearch(
      data?.organizations.find((organization) => organization.id === value)
        ?.details.displayName || ""
    );
  };

  useEffect(() => {
    if (value) {
      setManagerName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <InputGroup>
        <Input
          value={search}
          placeholder={t("Szukaj")}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() =>
            setFocused(() => {
              setManagerName();
              return false;
            })
          }
        />
        {value && (
          <InputRightElement width="4.5rem">
            <IconButton
              aria-label="clear"
              h="1.75rem"
              size="sm"
              variant="ghost"
              colorScheme="purple"
              icon={<ClearIcon />}
              onClick={() => {
                setSearch("");
                onChange(null);
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {focused && (
        <Box position="relative">
          <List
            position={"absolute"}
            zIndex={1}
            mt={2}
            maxH="300px"
            left={0}
            right={2}
            overflowY="auto"
            bgColor="white"
            boxShadow="md"
          >
            {options.map((option) => {
              return (
                <ListItem
                  key={option.id}
                  _hover={{ bgColor: "gray.100" }}
                  p={2}
                  cursor={
                    option.managersCountExcited ? "not-allowed" : "pointer"
                  }
                  opacity={option.managersCountExcited ? 0.5 : 1}
                  onMouseDown={() => {
                    if (!option.managersCountExcited) {
                      onChange(option.id);
                    }
                  }}
                  title={
                    option.managersCountExcited
                      ? t(
                          "Maksymalna liczba administratorów nie może przekraczać 50"
                        )
                      : undefined
                  }
                >
                  <HStack>
                    <Text fontWeight="500">{option.details.displayName}</Text>
                    <OrganizationStatusBadge status={option.status} />
                  </HStack>
                  <Text fontSize="sm" color="gray.600">
                    {option.details.email}
                  </Text>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </>
  );
};
