import { Button, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PlusIcon } from "components/icons/PlusIcon";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { AddManagerModal } from "./AddManagerModal";

interface IProps {
  organizationId: string;
  isLoading: boolean;
  managersCount: number;
}

const MAX_MANAGERS_COUNT = 50;

export const AddManagerBtn = ({
  organizationId,
  isLoading,
  managersCount,
}: IProps) => {
  const id = `add-manager`;
  const onOpen = useOpenDialog(id);
  const { data, isPending } = useOrganizationByIdQuery(organizationId!);

  if (isPending || isLoading) {
    return null;
  }

  if (
    data?.organization.status !== "ACTIVE" &&
    data?.organization.status !== "NEW"
  ) {
    return null;
  }

  if (managersCount >= MAX_MANAGERS_COUNT) {
    return (
      <Tooltip
        label={t("Maksymalna liczba administratorów nie może przekraczać 50")}
      >
        <Button
          size="sm"
          leftIcon={<PlusIcon />}
          colorScheme="purple"
          isDisabled
        >
          {t("Dodaj administratora")}
        </Button>
      </Tooltip>
    );
  }

  return (
    <>
      <AddManagerModal id={id} />
      <Button
        size="sm"
        leftIcon={<PlusIcon />}
        colorScheme="purple"
        onClick={onOpen}
      >
        {t("Dodaj administratora")}
      </Button>
    </>
  );
};
