import { Fragment } from "react/jsx-runtime";

import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { Modal, useOpenDialog } from "components/Dialog";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { HistoryIcon } from "components/icons/HistoryIcon";

import { Change } from "../application/types/Change";
import { useChangelogQuery } from "../infrastructure/useChangelogQuery";

const id = "changelog-modal";
export const useOpenChangelogModal = () => {
  return useOpenDialog(id);
};

export const ChangelogModal = () => {
  const { data, isLoading } = useChangelogQuery();

  return (
    <Modal id={id} size={"4xl"}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Zmiany w aplikacji")}</ModalHeader>
              <ModalCloseButton />

              <ModalBody as={VStack} align="stretch" spacing={6}>
                {isLoading ? (
                  <VStack align="stretch" spacing={6}>
                    <Skeleton h="35px" />;
                    <Skeleton h="175px" />;
                  </VStack>
                ) : (
                  <Content data={sortChanges(data?.changelog)} />
                )}
              </ModalBody>

              <ModalFooter>
                <ButtonGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Anuluj")}</Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};

interface IContentProps {
  data: Change[];
}

const Content = ({ data }: IContentProps) => {
  const [show, setShow] = useBoolean();
  const toggleCollapse = () => setShow.toggle();

  if (data.length === 0) {
    return (
      <VStack py={6}>
        <HistoryIcon size={"52px"} />
        <Text fontSize="lg" color="gray.600" textAlign="center">
          {t("Brak opublikowanych zmian")}
        </Text>
      </VStack>
    );
  }

  return (
    <VStack align="stretch" spacing={4}>
      <VStack align="stretch" spacing={1}>
        <Heading fontSize="lg" fontWeight="semibold">
          {data[0].title}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          <CalendarIcon size="18px" />
          {t(" Opublikowano: {{publishedAt}}", {
            publishedAt: dayjs(data[0].publishedAt).format("DD.MM.YYYY HH:mm"),
          })}
        </Text>
        <Box
          py={4}
          dangerouslySetInnerHTML={{
            __html: data[0].content,
          }}
          sx={{
            ul: {
              paddingLeft: "1.5rem",
            },
            ol: {
              paddingLeft: "1.5rem",
            },
          }}
        />
      </VStack>
      <Collapse in={show}>
        <VStack align="stretch" spacing={4}>
          {data.map((change, index) => {
            if (index === 0) return null; // zrobił bym to ładniej ale jakiś klient używa windowsa 7 i starego Chroma
            return (
              <Fragment key={change.id}>
                <Divider />
                <VStack align="stretch" spacing={1} key={change.id}>
                  <Heading fontSize="lg" fontWeight="semibold">
                    {change.title}
                  </Heading>
                  <Text fontSize="xs" color="gray.500">
                    <CalendarIcon size="18px" />
                    {t(" Opublikowano: {{publishedAt}}", {
                      publishedAt: dayjs(change.publishedAt).format(
                        "DD.MM.YYYY HH:mm"
                      ),
                    })}
                  </Text>
                  <Box
                    py={4}
                    dangerouslySetInnerHTML={{
                      __html: change.content,
                    }}
                    sx={{
                      ul: {
                        paddingLeft: "1.5rem",
                      },
                      ol: {
                        paddingLeft: "1.5rem",
                      },
                    }}
                  />
                </VStack>
              </Fragment>
            );
          })}
        </VStack>
      </Collapse>

      {data.length > 1 && (
        <Button onClick={toggleCollapse} fontWeight="600" w="100%" mt="4">
          {show ? t("Ukryj wszystkie") : t("Pokaż wszystkie")}
        </Button>
      )}
    </VStack>
  );
};

const sortChanges = (changes: Change[]) => {
  const publishedChanges = changes.filter(
    ({ publishedAt }) => publishedAt && dayjs(publishedAt).isBefore(dayjs())
  );

  const sortedRecords = reverse(
    sortBy(publishedChanges, function (changelog) {
      return new Date(changelog.publishedAt!);
    })
  );

  return sortedRecords;
};
