import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  contextId: string;
  email: string;
}

export const useInviteContextManager = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["unassign-context-manager"],
    mutationFn: (variables: MutationVariables) => {
      return client.put(
        `organizations/${organizationId}/contexts/${variables.contextId}/invite`,
        {
          body: {
            email: variables.email,
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "contexts"],
      });
    },
  });
};
