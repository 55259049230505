import { ReactNode } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  GridItem,
  HStack,
  Text,
} from "@chakra-ui/react";

interface IProps {
  title: string;
  icon: ReactNode;
  children: ReactNode;
}

export const DashboardTile = ({ icon, title, children }: IProps) => {
  return (
    <GridItem colSpan={2}>
      <Card height="100%">
        <CardHeader
          fontSize="lg"
          fontWeight="semibold"
          roundedTop="md"
          color="white"
          bgColor="purple.400"
        >
          <HStack justifyContent="space-between">
            <Text>{title}</Text>
            {icon}
          </HStack>
        </CardHeader>

        <CardBody>{children}</CardBody>
      </Card>
    </GridItem>
  );
};
