import { useNavigate } from "react-router-dom";

import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { GhostIcon } from "components/icons/GhostIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

interface IProps {
  email: string;
}

export const ImpersonateButton = ({ email }: IProps) => {
  const navigate = useNavigate();
  const { send } = ContextMachineContext.useActorRef();

  return (
    <Tooltip label={t("Impersonate")} aria-label={t("Impersonate")}>
      <IconButton
        variant="link"
        colorScheme="purple"
        size="sm"
        data-testid="impersonate-manager-button"
        onClick={async () => {
          send({ type: "impersonate", login: email });
          navigate("/");
        }}
        aria-label={t("Impersonate")}
        icon={<GhostIcon />}
      />
    </Tooltip>
  );
};
