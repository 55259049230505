import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Grid,
  Heading,
  HStack,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { EmptyState } from "components/EmptyStates/EmptyState";
import { StackedList } from "components/StackedList";
import { StackedListItem } from "components/StackedList/StackedListItem";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { NoDataIcon } from "components/icons/NoDataIcon";

import { NotificationProcess } from "modules/notifications/application/NotificationProcess";
import { useNotificationProcessQuery } from "modules/notifications/infrastructure/useNotificationProcessQuery";

import { DeleteNotificationBtn } from "./DeleteNotificationBtn";
import { EditNotificationBtn } from "./EditNotificationBtn";

interface IProps {
  isLoading: boolean;
  data: NotificationProcess[];
}

const NotificationDraftList = ({ isLoading, data }: IProps) => {
  if (isLoading) {
    return (
      <StackedList>
        <Skeleton h="140px" />
        <Skeleton h="140px" />
        <Skeleton h="140px" />
      </StackedList>
    );
  }

  if (data.length === 0) {
    return (
      <Grid placeItems="center" h="100%" w="100%">
        <EmptyState
          icon={<NoDataIcon />}
          size="xs"
          title={<Text fontWeight="500">{t("Brak wersji roboczych")}</Text>}
        />
      </Grid>
    );
  }

  const sortedRecords = reverse(
    sortBy(data, function (data) {
      return new Date(data.updatedAt || data.createdAt);
    })
  );

  return (
    <StackedList maxH="620px" data-testid="notification-drafts">
      {sortedRecords.map((notification) => {
        return (
          <StackedListItem
            key={notification.id}
            title={notification.subject}
            badges={notification.selectedTariffs}
            description={
              <HStack>
                <CalendarIcon size="16px" />
                <Text>
                  {t("Planowana wysyłka: {{sendAt}}", {
                    sendAt: notification.plannedSendAt
                      ? dayjs(notification.plannedSendAt).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      : t("natychmiast"),
                  })}
                </Text>
              </HStack>
            }
            actions={
              <>
                <EditNotificationBtn notificationProcessId={notification.id} />
                <DeleteNotificationBtn
                  notificationProcessId={notification.id}
                />
              </>
            }
          >
            <Box
              dangerouslySetInnerHTML={{ __html: notification.body }}
              sx={{
                // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "2",
                overflow: "hidden",
              }}
            />
          </StackedListItem>
        );
      })}
    </StackedList>
  );
};

export const NotificationDraftCard = (props: CardProps) => {
  const { data, isLoading } = useNotificationProcessQuery();

  return (
    <Card variant="sawpe" {...props} minW="315px">
      <CardHeader>
        <Heading fontSize="lg">{t("Wersje robocze")}</Heading>
      </CardHeader>
      <CardBody>
        <NotificationDraftList
          isLoading={isLoading}
          data={data?.notificationsProcesses.filter(
            ({ isLocked, isCompleted }) => !isLocked && !isCompleted
          )}
        />
      </CardBody>
    </Card>
  );
};
