import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IContextManagerResponse } from "../application/types/IContextManagerResponse";

const query = () => {
  return {
    queryKey: ["contextsManagers"],
    queryFn: () =>
      client.get<{ contextsManagers: IContextManagerResponse[] }>(
        `contextsManagers`
      ),
  };
};

export const getContextsManagersQuery = (queryClient: QueryClient) => () => {
  return queryClient.ensureQueryData(query());
};

export const useContextsManagersQuery = () => {
  return useQuery(query());
};

export const getContextsManagersMock = (
  contextsManagers: IContextManagerResponse[] = []
) => {
  return http.get(withHost("contextsManagers"), () => {
    return HttpResponse.json<{ contextsManagers: IContextManagerResponse[] }>({
      contextsManagers: [...contextsManagers],
    });
  });
};
