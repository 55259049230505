import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  Button,
  Input,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";
import { HTTPError } from "ky";

import { maxLength } from "utils/form/maxLength";
import { minLength } from "utils/form/minLength";

import { Modal } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useAddManager } from "modules/organization/infrastructure/useAddManager";
import { useInviteContextManager } from "modules/organization/infrastructure/useInviteContextManager";

import {
  IManagerFormData,
  ManagerModalForm,
} from "../../common/ManagerModalForm";

interface IProps {
  id: string;
}

export const AddManagerModal = ({ id }: IProps) => {
  const [contextManagerType, setContextManagerType] = useState<
    "new" | "existing"
  >("new");
  const { mutateAsync: addManager } = useAddManager();
  const isMutating = useIsMutating({
    mutationKey: ["add-manager"],
  });

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Dodaj administratora")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <RadioGroup
                  value={contextManagerType}
                  colorScheme="purple"
                  onChange={(type: "new" | "existing") => {
                    setContextManagerType(type);
                  }}
                >
                  <Stack spacing={5} direction="row">
                    <Radio value="new">{t("Dodaj administratora")}</Radio>
                    <Radio value="existing">{t("Zaproś administratora")}</Radio>
                  </Stack>
                </RadioGroup>
                {contextManagerType === "new" ? (
                  <ManagerModalForm
                    onClose={onClose}
                    onSubmit={async (data: IManagerFormData) => {
                      await addManager(data);
                    }}
                  />
                ) : (
                  <InviteManagerForm onClose={onClose} />
                )}
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  {contextManagerType === "new" ? (
                    <Button
                      colorScheme="purple"
                      type="submit"
                      form="manager-form"
                      data-testid="add-manager-button"
                      isLoading={!!isMutating}
                    >
                      {t("Dodaj administratora")}
                    </Button>
                  ) : (
                    <Button
                      colorScheme="purple"
                      type="submit"
                      form="invite-manager-form"
                      data-testid="invite-manager-button"
                      isLoading={!!isMutating}
                    >
                      {t("Zaproś")}
                    </Button>
                  )}
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};

interface IFormProps {
  onClose: () => void;
}

const InviteManagerForm = ({ onClose }: IFormProps) => {
  const contextId = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.id;
  const { organizationId } = useParams<{ organizationId: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    email: string;
  }>();
  const { mutateAsync: inviteManager } = useInviteContextManager(
    organizationId!
  );

  return (
    <form
      id="invite-manager-form"
      noValidate
      onSubmit={handleSubmit(async (data) => {
        try {
          await inviteManager({
            email: data.email,
            contextId: contextId!,
          });
          onClose();
        } catch (error) {
          if (error instanceof HTTPError) {
            const errorJson = await error.response.json<{
              message: string;
            }>();

            if (
              errorJson.message ===
              "context.error.contextManagerAlreadyAssigned"
            ) {
              return toastWithError({
                description: t("Admin już jest przypisany do podmiotu."),
                error,
              });
            }
          }

          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("E-mail")}
          isRequired
          formErrorMessage={errors.email?.message}
          formHelperText={t(
            'Jeśli wprowadzony przez Ciebie adres email będzie prawidłowym adresem istniejącego administratora na platformie weryfikacjapodpisu.pl to zostanie od natychmiast powiązany z Twoim podmiotem, w przeciwnym wypadku skorzystać z opcji "Dodaj administratora".'
          )}
        >
          <Input
            type="email"
            {...register("email", {
              setValueAs: (value) => value.trim(),
              required: t("Pole jest wymagane."),
              minLength: minLength(),
              maxLength: maxLength(),
              pattern: {
                value: /[^@]+@[^.]+\..+/,
                message: t("Niepoprawny format"),
              },
            })}
          />
        </FormControl>
      </VStack>
    </form>
  );
};
