import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { DeleteIcon } from "components/icons/DeleteIcon";

import { RemoveManagerConfirm } from "./RemoveManagerConfirm";

interface IProps {
  manager: {
    id: string;
    name: string;
  };
  organizationId: string;
  onlyOneActive: boolean;
}

export const RemoveManagerBtn = ({
  manager,
  onlyOneActive,
  organizationId,
}: IProps) => {
  const id = `remove-${manager.id}`;
  const onOpen = useOpenDialog(id);

  if (onlyOneActive) {
    return null;
  }

  return (
    <>
      <RemoveManagerConfirm
        id={id}
        contextId={manager.id}
        name={manager.name}
        organizationId={organizationId}
      />
      <Tooltip label={t("Usuń")} aria-label={t("Usuń")}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="remove-contextId"
          onClick={onOpen}
          aria-label={t("Usuń")}
          icon={<DeleteIcon />}
        />
      </Tooltip>
    </>
  );
};
