import { client } from "utils/http";

import { IContext } from "../application/types/IContext";

interface ILoginData {
  organizationId: string;
}

export const changeOrganization = async (loginData: ILoginData) => {
  return client.put<{ currentlySelectedContext: IContext }, ILoginData>(
    `auth/changeOrganization`,
    {
      body: loginData,
    }
  );
};
