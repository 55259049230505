import { withTranslation } from "react-i18next";

import { Card, CardBody, Heading, HStack, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { ManagersWithOrganizationsTable } from "modules/organization/presentation/ManagersWithOrganizationsTable";

export const ContextManagers = withTranslation()(() => {
  return (
    <VStack align="stretch" gap="8">
      <Card>
        <CardBody px={4} py={6}>
          <Heading fontSize="lg">
            {t("Lista administratorów podmiotów")}
          </Heading>
        </CardBody>
      </Card>
      <HStack gap="8" align="start">
        <VStack align="stretch" w="100%" gap="6">
          <ManagersWithOrganizationsTable />
        </VStack>
      </HStack>
    </VStack>
  );
});
