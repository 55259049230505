import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";

import { ErrorBoundary } from "components/ErrorBoundary";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { IOrganization } from "modules/organization/application/types/IOrganization";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { useVerificationProcessQuery } from "modules/verification/infrastructure/useVerificationProcessQuery";

import { ResultInterpretationRequest } from "../ResultInterpretationRequest";
import { SawpeWidget } from "./SawpeWidget";

interface EventResponse {
  source: "sawpe_widget";
  verificationStatus:
    | "verification.success"
    | "verification.failure"
    | "token.expired"
    | "history.result";
  verificationId?: string;
}

interface WidgetResponse {
  source: "sawpe_widget";
  widgetStatus: "widget.ready";
}

interface StartVerificationConnectedProps {
  contextId: string;
  organizationData: IOrganization;
  isLoading: boolean;
}

const StartVerificationConnected = ({
  contextId,
  organizationData,
  isLoading,
}: StartVerificationConnectedProps) => {
  const widgetRef = useRef<HTMLIFrameElement | null>(null);
  const [iframeHeight, setIframeHeight] = useState<number | undefined>();
  const [isWidgetReady, setIsWidgetReady] = useState<
    "ready" | "dead" | "pending"
  >("pending");

  const { data, isLoading: isProcessLoading } =
    useVerificationProcessQuery(contextId);
  const [verificationId, setVerificationId] = useState<string | undefined>();

  const [searchParams] = useSearchParams();
  const searchVerificationId = searchParams.get("searchVerificationId");

  const queryClient = useQueryClient();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isWidgetReady !== "ready") {
        setIsWidgetReady("dead");
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [isWidgetReady]);

  useEffect(() => {
    if (isWidgetReady === "ready" && data?.verificationProcess.id) {
      window.addEventListener("message", (event) => {
        if (event.data.frame_id === "myIframe") {
          if (event.data.height && Number.isInteger(event.data.height)) {
            setIframeHeight(event.data.height);
          }
        }
      });

      sendMessageToWidget(widgetRef.current!, {
        verificationProcessId: data?.verificationProcess.id,
        allowDispositionDownload:
          data?.verificationProcess.allowDispositionDownload,
        limit: data?.verificationProcess.filesVerificationLimit.limit,
      });
    }

    if (searchVerificationId) {
      sendMessageToWidget<{ verificationId: string }>(widgetRef.current!, {
        verificationId: searchVerificationId,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.verificationProcess.id, isWidgetReady]);

  useEffect(() => {
    const handleMessage = (
      event: MessageEvent<EventResponse | WidgetResponse>
    ) => {
      if (event.data.source === "sawpe_widget") {
        if ("widgetStatus" in event.data) {
          setIsWidgetReady("ready");
          return;
        }

        switch (event.data.verificationStatus) {
          case "token.expired":
            // trzeba wygenerować nowy klucz
            return;
          case "verification.failure":
            queryClient.invalidateQueries({
              queryKey: ["verificationProcess", "prepare"],
            });
            return;
          case "verification.success":
            queryClient.invalidateQueries({
              queryKey: ["organizations", organizationData.id, "details"],
            });
            queryClient.invalidateQueries({
              queryKey: ["verificationProcess", "prepare"],
            });
            setVerificationId(event.data.verificationId);
            return;
          case "history.result":
            setVerificationId(event.data.verificationId);
            return;
          default:
            return;
        }
      }
    };

    window.addEventListener("message", handleMessage, false);

    // Cleanup
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardBody>
        <VStack align="stretch">
          <Heading fontSize="lg" px="2" py="3">
            {t("Weryfikuj pliki")}
          </Heading>
        </VStack>
        <SawpeWidget
          ref={widgetRef}
          isWidgetReady={isWidgetReady}
          isLoading={isLoading || isProcessLoading}
          tariff={organizationData?.tariffs.current}
          verificationProcess={data?.verificationProcess}
          height={iframeHeight}
        />
        <ResultInterpretationRequest verificationId={verificationId} />
      </CardBody>
    </Card>
  );
};

export const StartVerification = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  if (!data?.organization.tariffs.current?.isActive) {
    return (
      <Card>
        <CardBody>
          <Alert
            status="warning"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {t("Brak aktywnego pakietu")}
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {t("Skontaktuj się z administratorem systemu.")}
            </AlertDescription>
          </Alert>
        </CardBody>
      </Card>
    );
  }

  if (!userInfo?.currentlySelectedContext?.id) {
    return null;
  }

  return (
    <ErrorBoundary>
      <StartVerificationConnected
        isLoading={isLoading}
        contextId={userInfo.currentlySelectedContext.id}
        organizationData={data?.organization}
      />
    </ErrorBoundary>
  );
};

interface EventData {
  allowDispositionDownload: boolean;
  verificationProcessId: string;
  limit: number | null;
}

const sendMessageToWidget = <T = EventData,>(
  target: HTMLIFrameElement,
  eventData: T
) => {
  const message = {
    source: "widget_parent",
    ...eventData,
  };

  target?.contentWindow?.postMessage(message, "*");
};
